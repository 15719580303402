.update-page input::placeholder {
    color: #757575 !important;
}

.update-page .main-title h2 {
    font-weight: 600 !important;
}

.update-page .sub-label {
    font-weight: normal !important;
}

.update-page {
    margin: auto;
    max-width: 1140px;
    margin-top: 0px;
    margin-bottom: 24px;
}

.update-page :global .ant-card-bordered {
    border: none;
    width: 100%;
}

.update-page :global .ant-card-body {
    padding: 0px !important;
}

.update-page .margin-bottom-12 {
    margin-bottom: -15px !important;
}

.update-page .margin-top-12 {
    margin-top: 4px !important;
    margin-bottom: -4px !important;
}

.security-questions :global .ant-select-item {
    width: 100%;
}

.update-page hr {
    margin-top: 20px;
    margin-bottom: 2px;
}

.update-page h5 {
    text-transform: none;
    font-size: 25px !important;
    margin-top: 12px !important;
    margin-bottom: 0px;
}

.update-page p {
    text-align: left;
    font-size: 20px;
    padding: 0.5rem;
    margin-bottom: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
}

.update-page .link {
    text-align: center;
    cursor: pointer;
}

.update-page .register-form-button,
.update-page .register-form-button:active,
.update-page .register-form-button:focus,
.update-page .register-form-button:hover {
    background: #ff8000;
    border: none;
    color: #fff;
    font-family: GlacialIndifference;
    margin-right: 0;
    text-transform: uppercase;
}

.update-page .empty-header-hero .title {
    font-size: 32px !important;
}

.electronics-terms-and-condition-card p .sub-text {
    font-size: 19px;
}

.electronics-terms-and-condition-screen h5 .small {
    font-size: 23px !important;
}

.electronics-terms-and-condition-screen .form-button,
.electronics-terms-and-condition-screen .form-button:active,
.electronics-terms-and-condition-screen .form-button:focus,
.electronics-terms-and-condition-screen .form-button:hover {
    background: #ff8000;
    border: none;
    color: #fff;
    font-family: GlacialIndifference;
    margin-right: 0;
    text-transform: uppercase;
}

.electronics-terms-and-condition-screen .form-button-back,
.electronics-terms-and-condition-screen .form-button-back:active,
.electronics-terms-and-condition-screen .form-button-back:focus,
.electronics-terms-and-condition-screen .register-form-button:hover {
    color: #fff;
    font-family: GlacialIndifference;
    margin-right: 0;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid #d9d9d9;
    background: #fff;
}

.update-page h5 {
    text-transform: none;
    font-size: 25px !important;
    margin-top: 0px !important;
    margin-bottom: 8px;
}

.update-page .btn-control button {
    border-radius: 12px;
    width: 150px;
}

.electronics-terms-and-condition-card p span .bold {
    font-family: GlacialInDifferenceBold;
}

.update-page .input-text,
.update-page :global(.ant-select-selector) {
    border: 1px solid #376998 !important;
    font-size: 19px !important;
    color: #000;
    height: 40px;
    opacity: 1;
}

.update-page :global .ant-card-body span {
    font-size: 16px;
}

.update-page :global .ant-card-body a .success-msg {
    font-size: 16px;
}

.update-page :global .ant-select,
.update-page :global .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
}

.update-page .text-wrapper {
    text-align: justify;
}

.update-page .dob {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    font-family: GlacialIndifference !important;
    opacity: 0.9;
    font-size: 1.2em !important;
    font-size: 19px !important;
    border: 1px solid #376998 !important;
}

.update-page :global .ant-select,
.update-page :global .ant-select-item {
    font-size: 16px !important;
}

.update-page .security {
    margin-top: 4px !important;
}

.update-page :global(.ant-select-selection-placeholder) {
    color: #000;
    font-size: 19px !important;
}

.update-page .margin-bottom-14 {
    margin-bottom: 14px !important;
}

.update-page .margin-bottom-12 {
    margin-bottom: 12px !important;
}

.update-page .margin-bottom-4 {
    margin-bottom: 4px !important;
}

.update-page .margin-bottom-0 {
    margin-bottom: 0px !important;
}

.update-page .success-msg {
    color: #294c7c !important;
}

.update-page .error-msg {
    color: #ff4d4f !important;
}

.update-page .button-submit-form {
    margin-top: 24px;
    margin-bottom: 16px;
}

.update-page .register-card {
    width: 100%;
}

.update-page :global .ant-select {
    width: 100%;
}

.update-page .submit-container {
    margin-top: 34px;
    max-width: 468px;
    margin: auto;
}

@media (max-width: 480px) {
    .update-page {
        width: 100%;
        padding-left: 22px !important;
        padding-right: 22px !important;
    }

    .register-card {
        width: 95% !important;
    }

    .update-page .margin-bottom-12 {
        margin-bottom: 15px !important;
    }

    .empty-header-hero {
        width: auto;
    }

    .empty-header-hero hr {
        width: 100%;
    }

    .update-page p {
        font-size: 17px;
        padding-bottom: 0;
    }

    .update-page h5 {
        font-family: GlacialInDifferenceBold;
        font-size: 19px !important;
        text-align: left;
        margin-top: 0;
        line-height: 1.4;
        font-weight: 700;
    }

    .update-page p {
        padding: 0;
    }

    .update-page .account-margin {
        margin-bottom: 8px;
    }

    .update-page .account-margin-select {
        margin-bottom: 16px;
    }

    .update-page .update-page .account-margin-select {
        margin-bottom: 0px;
    }

    .update-page .submit-container {
        justify-content: center;
    }

    .update-page span :global .ant-select-selection-item {
        font-size: 16px !important;
    }

    .update-page .empty-header-hero h2 {
        margin: 0 !important;
        line-height: 38px;
    }

    .update-page .electronics-terms-and-condition-screen {
        padding: 0px !important;
    }

    .update-page .margin-bottom-12 {
        margin-bottom: 12px !important;
    }
}

@media (min-width: 481px) and (max-width: 768px) {
    .update-page {
        width: 100%;
        padding-left: 48px !important;
    }

    .update-page .submit-container {
        justify-content: center;
    }

    .update-page .account-margin-select {
        margin-bottom: 14px !important;
    }

    .update-page .update-page .account-margin-select {
        margin-bottom: 0px !important
    }

    .register-card {
        width: 95% !important;
    }

    .update-page .margin-bottom-12 {
        margin-bottom: 12px !important;
    }

    .empty-header-hero .title {
        margin-left: 45px !important;
        font-size: 40px !important;
    }

    .empty-header-hero {
        width: auto;
    }

    .empty-header-hero hr {
        width: 100%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .update-page {
        width: 100%;
        padding-left: 55px !important;
    }

    .register-card {
        width: 95% !important;
    }

    .empty-header-hero .title {
        margin-left: 50px !important;
    }

    .empty-header-hero {
        width: auto;
    }

    .empty-header-hero hr {
        width: 100%;
    }
}

p .update-copy-black {
    font-size: 19px;
}

ul .update-copy-black {
    list-style-type: disc;
    list-style-position: inside;
    font-size: 17px;
    margin-bottom: 0px;
}

@media(max-width: 480px) {
    ul .update-copy-black {
        margin-top: 10px;
    }

    p .update-copy-black:nth-of-type(2) {
        margin-bottom: 10px !important;
    }

    p .update-copy-black {
        margin-top: 10px;
        padding-bottom: 10px;
    }
}

a .error-msg {
    font-weight: bold;
    text-decoration: underline;
}
